export const places = [
  {
    place: "Paris, France",
    title: "The iconic symbol of Paris.",
    image:
      "https://images.pexels.com/photos/26108645/pexels-photo-26108645/free-photo-of-silhouette-of-eiffel-tower-during-sunset.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    place: "Rome, Italy",
    title: "The ancient amphitheater where gladiators once fought.",
    image:
      "https://images.pexels.com/photos/26201027/pexels-photo-26201027/free-photo-of-view-of-the-colosseum.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    place: " Barcelona, Spain",
    title: "Antoni Gaudí's unfinished masterpiece",
    image:
      "https://images.pexels.com/photos/819764/pexels-photo-819764.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    place: " Amsterdam, Netherlands",
    title: "traditional Japanese culture",
    image:
      "https://images.pexels.com/photos/402028/pexels-photo-402028.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
];
