import logo from "./logo.svg";
import "./App.css";
import Home from "./Pages/Home/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Contact from "./Pages/Contact/Contact";
import Destination from "./Pages/Destination/Destination";
import About from "./Pages/About/About";
import Terms from "./Pages/Policy/Terms";
import Policy from "./Pages/Policy/Policy";
import Airlines from "./Pages/Airlines/Airline";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/in/policy" element={<Policy />} />
        <Route path="/in/terms" element={<Terms />} />
        <Route path="/in/airline/:destiny" element={<Airlines />} />
        <Route path="/in/destination/:destiny" element={<Destination />} />
        <Route path="/in/about" element={<About />} />
        <Route path="/in/contact" element={<Contact />} />
        <Route
          path="/"
          element={
            <div>
              <Home />
            </div>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
