import React from "react";
import { Link } from "react-router-dom";
import { destinations } from "../../Container/Data/destination";
import { IoMdCall } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import { airline } from "../../Container/Data/Airlines";
import Custom from "../../assets/images/custom.png";

function Footer() {
  return (
    <div className="pt-24 bg-[#f3f3f3] px-24  max-lg:px-16">
      <div className=" w-full flex items-start justify-between max-lg:flex-wrap">
        <div className="w-1/4 mx-4 max-lg:w-1/2 max-lg:mb-3 max-lg:mx-0 max-md:w-full">
          <div className="font-bold text-3xl">Quick Links</div>
          <div className="mt-3 text-lg">
            <Link to="/">
              <div className="mb-2">Home</div>
            </Link>
            <Link to="/in/about">
            <div className="mb-2">About us</div>
            </Link>
            <Link to="/in/contact">
              <div className="mb-2">Contact us</div>
            </Link>
          </div>
        </div>
        <div className="w-1/4 mx-4 max-lg:w-1/2 max-lg:mb-3 max-md:w-full  max-lg:mx-0">
          <div className="font-bold text-2xl">Top Destinations</div>
          <div className="mt-3 text-lg">
            {destinations.map((destination, index) => (
              <Link
                to={`/in/destination/${destination.title
                  .split(" ")
                  .join("")
                  .toLowerCase()}`}
              >
                <div key={index} className="py-2 hover:bg-gray-200">
                  {destination.title}
                </div>
              </Link>
            ))}
          </div>
        </div>
        <div className="w-1/4 mx-4 max-lg:w-1/2 max-lg:mb-3 max-lg:mx-0 max-md:w-full">
          <div className="font-bold text-2xl">Top Airlines</div>
          <div className="mt-3 text-lg">
            {airline.map((destination, index) => (
              <Link
                to={`/in/airline/${destination.title
                  .split(" ")
                  .join("")
                  .toLowerCase()}`}
              >
                <div key={index} className="py-2 hover:bg-gray-200">
                  {destination.title}
                </div>
              </Link>
            ))}
          </div>
        </div>
        <div className="w-1/4 mx-4 max-lg:w-1/2 max-lg:mb-3 max-lg:mx-0 max-md:w-full">
          <div className="font-bold text-3xl">Contact Us</div>
          <a href="tel:+18338352670" className="flex items-center mt-6 mb-3">
            <div className="mr-3 p-4 rounded-full bg-[#ff8a63]">
              <img src={Custom} alt="" className="w-9" />
            </div>
            <div>
              <div>+1-833-835-2670</div>
              <span className="text-xs">( Call for booking )</span>
            </div>
          </a>
          <div className="flex items-start mt-6 mb-3">
            <div className="mr-3 p-4 rounded-full bg-[#ff8a63]">
              <FaLocationDot size={19} />
            </div>

            <span>
              9407 Ne Vancouver Mall Dr, Ste 104 #1647 Vancouver, Washington
              98662 Us
            </span>
          </div>
        </div>
      </div>
      <div className="py-3 mt-12 border border-t-black/20">
        <div className="flex text-xs font-medium items-center">
          <Link to="/in/terms">
            <div className="mx-3">Terms & conditions</div>
          </Link>
          <Link to="/in/policy">
            <div className="mx-3">Privacy policy</div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Footer;
