export const destinations = [
  {
    title: "Amsterdam",
    image:
      "https://images.pexels.com/photos/1796705/pexels-photo-1796705.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    content: `<div class="container mx-auto p-8">
        <div >
            
            <p class="text-lg mb-4">
                Amsterdam, the vibrant capital of the Netherlands, is a popular destination due to its rich cultural heritage, picturesque canals, and diverse attractions. Renowned for its unique blend of historic charm and modern vibrancy, Amsterdam offers visitors a captivating experience.
            </p>
            <p class="text-lg mb-4">
                The city is famous for its extensive canal system, picturesque bridges, and historic architecture. Iconic landmarks such as the Anne Frank House, the Van Gogh Museum, and the Rijksmuseum draw millions of visitors annually. Amsterdam’s museums and galleries house world-class art collections and historical exhibits.
            </p>
            <p class="text-lg mb-4">
                The city’s charming neighborhoods, such as Jordaan and De Pijp, are perfect for leisurely strolls and offer a mix of boutique shops, cafes, and local markets. Amsterdam is also known for its lively cultural scene, including music festivals, theater performances, and an active nightlife.
            </p>
            <p class="text-lg">
                Cycling is an integral part of Amsterdam’s lifestyle, with bike-friendly streets and picturesque bike paths making it easy for visitors to explore. The city’s welcoming atmosphere, combined with its historic charm and modern amenities, makes Amsterdam a beloved destination for travelers from around the world.
            </p>
        </div>
    </div>`,
  },
  {
    title: "London",
    image:
      "https://images.pexels.com/photos/460672/pexels-photo-460672.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    content: `<div >
           
            <p class="text-lg mb-4">
                London, the capital of the United Kingdom, is a globally popular destination due to its rich history, cultural diversity, and iconic landmarks. The city boasts an impressive array of historical sites, including the Tower of London, Buckingham Palace, and Westminster Abbey, each offering a glimpse into Britain’s storied past.
            </p>
            <p class="text-lg mb-4">
                London is also renowned for its world-class museums and galleries, such as the British Museum, the Tate Modern, and the National Gallery, which house invaluable art and historical artifacts. The city’s vibrant theater scene in the West End attracts millions of visitors, showcasing a range of performances from classic plays to contemporary musicals.
            </p>
            <p class="text-lg mb-4">
                The diverse culinary scene and eclectic neighborhoods, like Covent Garden, Camden, and Soho, provide a wealth of dining and shopping experiences. London’s green spaces, including Hyde Park and Kew Gardens, offer tranquil escapes from the bustling city life.
            </p>
            <p class="text-lg">
                Moreover, London is a major financial and cultural hub, with its global influence in fashion, media, and politics. Its extensive public transportation network makes it easy for visitors to explore the city’s many attractions. The combination of historical grandeur, cultural richness, and modern amenities makes London a top destination for travelers worldwide.
            </p>
        </div>`,
  },
  {
    title: "Berlin",
    image:
      "https://images.pexels.com/photos/1128408/pexels-photo-1128408.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    content: `<div >
           
            <p class="text-lg mb-4">
                Berlin, the vibrant capital of Germany, is a popular destination known for its dynamic history, artistic flair, and cultural diversity. The city is celebrated for its historical landmarks, including the Berlin Wall, Brandenburg Gate, and the Holocaust Memorial, which reflect its complex past and offer profound insights into 20th-century history.
            </p>
            <p class="text-lg mb-4">
                Berlin’s cultural scene is thriving, with a wealth of world-class museums and galleries such as the Museum Island complex and the East Side Gallery. The city's lively arts scene encompasses everything from avant-garde theater to underground music, making it a hub for creative expression.
            </p>
            <p class="text-lg mb-4">
                The city's eclectic neighborhoods, like Kreuzberg and Mitte, offer an array of dining, shopping, and nightlife options, showcasing Berlin’s multicultural character. Its extensive green spaces, including Tiergarten and Tempelhofer Feld, provide relaxing retreats amid urban exploration.
            </p>
            <p class="text-lg">
                Berlin’s reputation for being a welcoming and inclusive city, combined with its affordable cost of living compared to other major European capitals, makes it an attractive destination for both tourists and expatriates. Its rich cultural tapestry, historical significance, and vibrant modern life make Berlin a compelling and diverse city to explore.
            </p>
        </div>`,
  },
  {
    title: "Vienna",
    image:
      "https://images.pexels.com/photos/161074/vienna-st-charles-s-church-downtown-church-161074.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    content: `<div >
            <h1 class="text-3xl font-bold mb-4 text-center">Vienna</h1>
            <p class="text-lg mb-4">
                Vienna, the capital of Austria, is a popular destination renowned for its imperial charm, artistic heritage, and vibrant cultural scene. The city’s rich history is showcased in its stunning architecture, including the opulent Schönbrunn and Belvedere Palaces, and historic landmarks like St. Stephen’s Cathedral and the Hofburg Palace.
            </p>
            <p class="text-lg mb-4">
                Vienna is celebrated for its classical music heritage, with its historic opera houses, such as the Vienna State Opera, and concert halls like the Musikverein, which regularly feature performances by world-class orchestras and soloists. The city’s musical legacy is further enriched by its association with composers like Mozart, Beethoven, and Strauss.
            </p>
            <p class="text-lg mb-4">
                The city’s café culture, exemplified by historic establishments like Café Central and Café Sacher, invites visitors to enjoy Viennese pastries and coffee in an elegant setting. Vienna's lively arts scene includes numerous museums, such as the Kunsthistorisches Museum and the Leopold Museum, which house impressive collections of art and historical artifacts.
            </p>
            <p class="text-lg">
                Vienna’s well-preserved green spaces, including the Stadtpark and the Vienna Woods, offer relaxing retreats within the city. Its blend of historical grandeur, cultural richness, and modern sophistication makes Vienna an attractive and enchanting destination for travelers.
            </p>
        </div>`,
  },
  {
    title: "Dublin",
    image:
      "https://images.pexels.com/photos/823696/pexels-photo-823696.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    content: `<div >
            
            <p class="text-lg mb-4">
                Dublin, the capital of Ireland, is a popular destination known for its vibrant culture, rich history, and friendly atmosphere. The city’s charm is reflected in its historic landmarks, including Dublin Castle, Trinity College (home to the Book of Kells), and the elegant Georgian architecture of Merrion Square and Fitzwilliam Square.
            </p>
            <p class="text-lg mb-4">
                Dublin is renowned for its lively literary heritage, being the birthplace of famous writers such as James Joyce, Oscar Wilde, and Samuel Beckett. The city's literary traditions are celebrated at landmarks like the Dublin Writers Museum and the annual Dublin International Literary Award.
            </p>
            <p class="text-lg mb-4">
                The city's pub culture is another major draw, with traditional Irish pubs like The Temple Bar offering live music, hearty food, and a warm, welcoming environment. Dublin's thriving cultural scene includes numerous theaters, galleries, and festivals, adding to the city's vibrant atmosphere.
            </p>
            <p class="text-lg">
                Dublin’s compact size makes it easy to explore on foot, with attractions like the historic Kilmainham Gaol, the scenic River Liffey, and the sprawling Phoenix Park all within reach. The city’s blend of historical depth, literary legacy, and lively social scene makes Dublin a compelling destination for visitors.
            </p>
        </div>`,
  },
  {
    title: "Barcelona",
    image:
      "https://images.pexels.com/photos/819764/pexels-photo-819764.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    content: `<div >
           
            <p class="text-lg mb-4">
                Barcelona, the capital of Catalonia in Spain, is a popular destination known for its unique blend of architectural marvels, vibrant culture, and beautiful Mediterranean setting. The city’s architectural heritage is highlighted by the works of Antoni Gaudí, including the iconic Sagrada Família, Park Güell, and Casa Batlló, which attract art and history enthusiasts from around the world.
            </p>
            <p class="text-lg mb-4">
                Barcelona’s lively cultural scene extends to its museums, such as the Picasso Museum and the Joan Miró Foundation, which showcase the works of these renowned artists. The city's vibrant neighborhoods, like the Gothic Quarter and El Raval, offer charming streets, boutique shops, and a variety of dining options, reflecting the city's rich history and multicultural character.
            </p>
            <p class="text-lg mb-4">
                The city is also celebrated for its stunning beaches along the Mediterranean coast, providing a perfect retreat for relaxation and recreation. Barcelona’s dynamic nightlife, with its array of tapas bars, clubs, and live music venues, adds to the city’s allure.
            </p>
            <p class="text-lg">
                Additionally, the city’s favorable climate, with mild winters and warm summers, makes it an attractive year-round destination. Barcelona’s architectural beauty, cultural vibrancy, and coastal charm make it a top choice for travelers seeking a memorable and enriching experience.
            </p>
        </div>`,
  },
  {
    title: "Athens",
    image:
      "https://images.pexels.com/photos/164336/pexels-photo-164336.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    content: `<div >
           
            <p class="text-lg mb-4">
                Athens, the capital of Greece, is a popular destination renowned for its rich historical legacy, vibrant culture, and iconic landmarks. The city is famously home to some of the world’s most significant ancient monuments, including the Acropolis, which houses the Parthenon, the Erechtheion, and the Temple of Athena Nike. These landmarks offer visitors a glimpse into the grandeur of ancient Greek civilization.
            </p>
            <p class="text-lg mb-4">
                Athens is also celebrated for its dynamic cultural scene, with numerous museums such as the National Archaeological Museum and the Acropolis Museum showcasing ancient artifacts and art. The city’s lively neighborhoods, like Plaka and Monastiraki, feature charming streets, local markets, and a variety of dining options, providing a taste of traditional Greek life.
            </p>
            <p class="text-lg mb-4">
                The city’s mild Mediterranean climate, with warm summers and mild winters, makes it an inviting destination year-round. Athens also serves as a gateway to nearby Greek islands, adding to its appeal as a base for exploring the Aegean Sea.
            </p>
            <p class="text-lg">
                Athens’ blend of ancient history, cultural vibrancy, and modern amenities makes it a compelling destination for travelers interested in exploring the roots of Western civilization while enjoying a dynamic urban experience.
            </p>
        </div>`,
  },
  {
    title: "Prague",
    image:
      "https://images.pexels.com/photos/1269805/pexels-photo-1269805.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    content: `<div >
            
            <p class="text-lg mb-4">
                Prague, the capital of the Czech Republic, is a popular destination celebrated for its stunning medieval architecture, vibrant cultural scene, and charming ambiance. The city’s historic center, a UNESCO World Heritage Site, is renowned for its well-preserved Gothic, Baroque, and Renaissance buildings. Key attractions include Prague Castle, a sprawling complex with panoramic views, and the iconic Charles Bridge, adorned with statues and offering picturesque river views.
            </p>
            <p class="text-lg mb-4">
                The Old Town Square, home to the famous Astronomical Clock, is a lively hub where visitors can experience local markets, cafes, and historical landmarks. Prague’s cobblestone streets and charming neighborhoods, such as Mala Strana and Vinohrady, are perfect for leisurely exploration and discovering hidden gems.
            </p>
            <p class="text-lg mb-4">
                Culturally, Prague boasts an array of theaters, museums, and galleries, including the National Theatre and the Prague National Gallery, which showcase the city’s artistic heritage. The city’s vibrant nightlife, affordable dining options, and scenic setting along the Vltava River further enhance its appeal.
            </p>
            <p class="text-lg">
                Prague’s blend of historical allure, cultural richness, and scenic beauty makes it a captivating destination for travelers seeking an unforgettable European experience.
            </p>
        </div>`,
  },
  {
    title: "Copenhagen",
    image:
      "https://images.pexels.com/photos/415722/pexels-photo-415722.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    content: `<div >
            
            <p class="text-lg mb-4">
                Copenhagen, the capital of Denmark, is a popular destination renowned for its blend of modern charm, historical elegance, and vibrant cultural scene. The city is celebrated for its picturesque canals, historic architecture, and iconic landmarks, such as the Tivoli Gardens amusement park, the Little Mermaid statue, and the stunning Nyhavn waterfront, with its colorful 17th-century buildings and lively atmosphere.
            </p>
            <p class="text-lg mb-4">
                Copenhagen’s commitment to sustainability and quality of life is evident in its extensive bike lanes, green spaces, and eco-friendly initiatives. The city’s culinary scene is also a major draw, with a range of dining options from traditional Danish fare to innovative restaurants, including several Michelin-starred establishments like Noma.
            </p>
            <p class="text-lg mb-4">
                The city’s rich cultural offerings include world-class museums such as the National Museum of Denmark and the Louisiana Museum of Modern Art. The design and fashion scene is equally vibrant, with trendy boutiques and design shops scattered throughout the city.
            </p>
            <p class="text-lg">
                Copenhagen’s friendly, welcoming atmosphere, combined with its efficient public transportation and scenic beauty, makes it an appealing destination for travelers seeking a blend of history, culture, and contemporary style.
            </p>
        </div>`,
  },
  {
    title: "Budapest",
    image:
      "https://images.pexels.com/photos/732057/pexels-photo-732057.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    content: `<div >
           
            <p class="text-lg mb-4">
                Budapest, the capital of Hungary, is a popular destination known for its stunning architecture, rich history, and vibrant cultural scene. The city, divided by the Danube River into Buda and Pest, offers a diverse array of attractions. The Buda side is home to the majestic Buda Castle, Matthias Church, and the Fisherman’s Bastion, which provide panoramic views of the city and showcase Budapest’s medieval charm.
            </p>
            <p class="text-lg mb-4">
                On the Pest side, visitors can explore the grand Parliament Building, the bustling Andrassy Avenue, and the iconic St. Stephen’s Basilica. The city is also famous for its thermal baths, such as Széchenyi and Gellért, which offer a relaxing experience and highlight Budapest’s tradition of spa culture.
            </p>
            <p class="text-lg mb-4">
                Budapest’s cultural vibrancy is evident in its numerous museums, theaters, and opera houses, including the Hungarian State Opera House and the Museum of Fine Arts. The city’s lively nightlife, with its ruin bars and eclectic mix of cafes and restaurants, adds to its appeal.
            </p>
            <p class="text-lg">
                With its blend of historic grandeur, cultural richness, and scenic beauty along the Danube, Budapest provides a captivating and memorable experience for travelers.
            </p>
        </div>`,
  },
];
