export const airline = [
  {
    title: "Cape Air",
    image:
      "https://images.pexels.com/photos/26131248/pexels-photo-26131248/free-photo-of-plane-on-an-airport.jpeg?auto=compress&cs=tinysrgb&w=600",
    content: `<div >
        <h1 class="text-3xl font-bold mb-6">Cape Air: An Overview of Policies, Facilities, and Benefits</h1>
        <p class="mb-6">Cape Air, officially known as Hyannis Air Service, Inc., is a well-regarded regional airline that has been serving communities across the United States and the Caribbean since its inception in 1989. With a reputation for reliability and community-oriented service, Cape Air offers a range of policies, facilities, and benefits that make it a preferred choice for many travelers.</p>

        <h2 class="text-2xl font-semibold mb-4">Policies</h2>
        <p class="mb-4">Cape Air is committed to ensuring a safe, comfortable, and efficient travel experience for all its passengers. Below are some of the key policies that define the airline's operations:</p>
        
        <h3 class="text-xl font-semibold mb-2">1. Baggage Policy</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Carry-On:</strong> Cape Air allows one personal item and one carry-on bag per passenger. The carry-on bag must fit in the overhead bin or under the seat in front of you.</li>
            <li><strong>Checked Baggage:</strong> Passengers are typically allowed to check one or two bags, depending on the route and fare type. Weight and size restrictions apply, and additional fees may be charged for overweight or oversized bags.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">2. Booking and Cancellation</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Flexible Booking:</strong> Cape Air offers flexible booking options, allowing passengers to make changes to their itineraries with ease. However, changes may be subject to fare differences.</li>
            <li><strong>Cancellation Policy:</strong> Passengers can cancel their reservations up to a certain period before departure, with refunds or credits issued depending on the fare type and timing of the cancellation.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">3. Special Assistance</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Accessibility:</strong> Cape Air is dedicated to accommodating passengers with disabilities or special needs. The airline provides assistance with boarding, seating, and other services to ensure a comfortable journey.</li>
            <li><strong>Pets:</strong> Small pets are allowed in the cabin for an additional fee, provided they meet the airline’s requirements. Cape Air also accommodates service animals, as per the guidelines.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">4. Safety and Security</h3>
        <p class="mb-4">Cape Air adheres to strict safety protocols and works closely with aviation authorities to maintain the highest standards of operational safety. This includes regular maintenance of aircraft, thorough crew training, and compliance with all federal regulations.</p>

        <h2 class="text-2xl font-semibold mb-4">Facilities</h2>
        <p class="mb-4">Cape Air, despite being a regional airline, offers a range of facilities designed to enhance the passenger experience:</p>
        
        <h3 class="text-xl font-semibold mb-2">1. Aircraft</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Fleet:</strong> Cape Air operates a fleet of primarily Cessna 402 and Tecnam P2012 Traveller aircraft, known for their reliability and comfort on short-haul flights. These aircraft are well-maintained and equipped with necessary safety features.</li>
            <li><strong>Configuration:</strong> The airline’s aircraft are configured to provide a cozy, small-plane experience, often offering views of the cockpit, which adds a unique charm to the flying experience.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">2. Lounges and Waiting Areas</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Airport Amenities:</strong> At select airports, Cape Air provides comfortable waiting areas with amenities such as Wi-Fi, refreshments, and seating. These areas are designed to offer a pleasant environment while waiting for flights.</li>
            <li><strong>Lounges:</strong> While Cape Air does not operate its own lounges, passengers can often access airport lounges through partnerships or by holding a qualifying frequent flyer status with partner airlines.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">3. Onboard Services</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>In-Flight Services:</strong> Given the short duration of most Cape Air flights, in-flight services are minimal. However, passengers can expect complimentary water and light snacks on certain routes.</li>
            <li><strong>Service:</strong> The airline focuses on offering a friendly and personalized service, with flight attendants and crew members often going out of their way to ensure passenger comfort.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">4. Customer Service</h3>
        <p class="mb-4">Cape Air is known for its exceptional customer service, both on the ground and in the air. The airline’s staff is trained to assist passengers with a range of needs, from booking inquiries to in-flight assistance.</p>

        <h2 class="text-2xl font-semibold mb-4">Airline Benefits</h2>
        <p class="mb-4">Flying with Cape Air comes with several benefits, especially for those who value convenience and regional connectivity:</p>
        
        <h3 class="text-xl font-semibold mb-2">1. Community Focus</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Community Role:</strong> Cape Air is deeply rooted in the communities it serves. The airline plays a vital role in connecting smaller, underserved regions with larger hubs, fostering economic development and providing essential air service.</li>
            <li><strong>Commitment:</strong> The airline’s commitment to community is reflected in its sponsorships, local partnerships, and involvement in regional events.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">2. Frequent Flyer Program</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Partnerships:</strong> Cape Air participates in codeshare agreements with major airlines like JetBlue, American Airlines, and United Airlines, allowing passengers to earn and redeem miles through these partners’ frequent flyer programs.</li>
            <li><strong>Rewards:</strong> This partnership offers passengers the opportunity to accrue rewards, even on short regional flights, making it a valuable option for frequent travelers.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">3. Convenient Schedules</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Frequency:</strong> Cape Air operates frequent flights on its routes, providing flexibility and convenience for passengers. This is especially beneficial for business travelers or those needing to make quick, last-minute trips.</li>
            <li><strong>Reliability:</strong> The airline’s reliable schedules also make it a preferred choice for island-hopping in the Caribbean or reaching remote destinations in the U.S.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">4. Environmental Commitment</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Sustainability:</strong> Cape Air is committed to reducing its environmental footprint. The airline has invested in more fuel-efficient aircraft and works to minimize its carbon emissions through various initiatives.</li>
            <li><strong>Support:</strong> Additionally, Cape Air supports sustainable tourism efforts in the regions it serves, contributing to the preservation of natural resources and local cultures.</li>
        </ul>
    </div>`,
  },
  {
    title: "Air Wisconsin",
    image: "",
    content: `<div >
        <h1 class="text-3xl font-bold mb-6">Air Wisconsin: Policies, Facilities, and Airline Benefits</h1>
        <p class="mb-6">Air Wisconsin, established in 1965, is one of the oldest regional airlines in the United States, playing a crucial role in connecting smaller communities with major airline hubs. Over the decades, the airline has grown to be a key partner with major carriers, ensuring that passengers from less-served areas have access to broader air travel networks. In this article, we explore Air Wisconsin's policies, facilities, and the benefits it offers to its passengers.</p>

        <h2 class="text-2xl font-semibold mb-4">Policies</h2>
        <p class="mb-4">Air Wisconsin is dedicated to providing a seamless and safe travel experience for its passengers. The airline operates under a set of well-defined policies that prioritize safety, flexibility, and customer satisfaction:</p>
        
        <h3 class="text-xl font-semibold mb-2">1. Baggage Policy</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Carry-On:</strong> Passengers are allowed one carry-on bag and one personal item. The carry-on bag must fit in the overhead bin or under the seat in front of the passenger.</li>
            <li><strong>Checked Baggage:</strong> Air Wisconsin follows the baggage policies of the major airline partners it operates for (like American Airlines or United Airlines). Typically, passengers can check one or two bags, with specific weight and size limits depending on the fare class and route.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">2. Booking and Cancellation</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Flexible Booking:</strong> Air Wisconsin offers flexible booking options through its partner airlines, allowing passengers to modify their travel plans as needed. Fees for changes or cancellations are governed by the policies of the operating partner.</li>
            <li><strong>Refunds and Cancellations:</strong> Refund policies are aligned with the major carriers Air Wisconsin partners with, offering refunds or credits depending on the fare type and timing of the cancellation.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">3. Special Assistance</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Accessibility:</strong> Air Wisconsin is committed to providing a safe and comfortable travel experience for all passengers, including those with disabilities. The airline offers assistance with boarding, deplaning, and in-flight needs.</li>
            <li><strong>Service Animals:</strong> Passengers requiring service animals are accommodated under the guidelines of the operating partner airline, ensuring that all necessary support is available throughout the journey.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">4. Safety and Security</h3>
        <p class="mb-4">Air Wisconsin places a high priority on safety and complies with all Federal Aviation Administration (FAA) regulations. The airline conducts regular maintenance on its aircraft and ensures that its crew is trained to handle all aspects of flight safety.</p>

        <h2 class="text-2xl font-semibold mb-4">Facilities</h2>
        <p class="mb-4">As a regional carrier, Air Wisconsin operates smaller aircraft and provides facilities designed to cater to short-haul flights. Despite this, the airline ensures that passengers have access to necessary amenities that make their travel experience pleasant:</p>
        
        <h3 class="text-xl font-semibold mb-2">1. Aircraft</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Fleet:</strong> Air Wisconsin primarily operates CRJ-200 regional jets, which are known for their efficiency and reliability on shorter routes. These jets typically seat around 50 passengers, providing an intimate and comfortable travel experience.</li>
            <li><strong>Configuration:</strong> The aircraft are equipped with essential safety features, and the interior is designed to offer a cozy atmosphere for passengers during their journey.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">2. Airport Facilities</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Airport Amenities:</strong> While Air Wisconsin does not operate its own dedicated lounges, passengers can often access airport facilities through the major carriers it partners with. This includes access to lounges, priority boarding, and other amenities for eligible passengers.</li>
            <li><strong>General Facilities:</strong> At most airports, Air Wisconsin passengers can take advantage of the amenities provided by the airport itself, such as seating areas, Wi-Fi, and dining options.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">3. Onboard Services</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>In-Flight Services:</strong> Given the short duration of most flights, onboard services are relatively basic. Passengers can expect complimentary snacks and beverages on most flights.</li>
            <li><strong>Service:</strong> The airline focuses on maintaining a high standard of in-flight service, with friendly crew members who strive to make the travel experience as enjoyable as possible.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">4. Customer Service</h3>
        <p class="mb-4">Air Wisconsin is known for its excellent customer service, with a focus on ensuring passenger satisfaction. The airline's staff, both on the ground and in the air, are trained to assist with a wide range of needs, from booking inquiries to in-flight requests.</p>

        <h2 class="text-2xl font-semibold mb-4">Airline Benefits</h2>
        <p class="mb-4">Flying with Air Wisconsin offers several benefits, particularly for passengers who value connectivity, convenience, and the advantages of partnering with major airlines:</p>
        
        <h3 class="text-xl font-semibold mb-2">1. Partnerships with Major Airlines</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Major Partnerships:</strong> Air Wisconsin operates flights under the banners of major airlines like American Airlines (as American Eagle) and United Airlines (as United Express). This partnership allows passengers to seamlessly connect to larger networks, accessing a wide range of destinations across the country and internationally.</li>
            <li><strong>Coordinated Scheduling:</strong> Passengers benefit from coordinated scheduling, allowing for smooth connections between regional flights operated by Air Wisconsin and long-haul flights with the partner airlines.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">2. Frequent Flyer Program</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Miles Accumulation:</strong> As a partner of major airlines, Air Wisconsin flights typically earn miles in the frequent flyer programs of those carriers. This means that passengers can accumulate miles on their short regional flights and redeem them for upgrades, free flights, and other rewards.</li>
            <li><strong>Elite Benefits:</strong> Elite status holders with partner airlines can enjoy benefits such as priority boarding, extra baggage allowance, and access to premium lounges when flying on Air Wisconsin-operated flights.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">3. Regional Connectivity</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Connecting Communities:</strong> Air Wisconsin specializes in connecting smaller cities and towns with larger airport hubs, providing essential air service to communities that might otherwise be underserved. This regional connectivity is crucial for business travelers, tourists, and residents in these areas.</li>
            <li><strong>Efficient Service:</strong> The airline’s reliable service on these routes ensures that passengers can reach their destinations efficiently, without the need for long drives or multiple connecting flights.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">4. Operational Efficiency</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Fleet Efficiency:</strong> Air Wisconsin’s fleet of regional jets is optimized for short-haul flights, offering a balance of comfort and efficiency. The airline’s operational focus on regional routes allows it to maintain punctuality and reliability, reducing the likelihood of delays or cancellations.</li>
            <li><strong>Timely Departures:</strong> Passengers can rely on Air Wisconsin for timely departures and arrivals, making it a preferred choice for those who value time and convenience.</li>
        </ul>
    </div>`,
  },
  {
    title: "Breeze Airways",
    image: "",
    content: ` <div >
        <h1 class="text-3xl font-bold mb-6">Breeze Airways: Policies, Facilities, and Benefits</h1>
        <p class="mb-6">Breeze Airways is a relatively new entrant to the U.S. airline industry, founded by David Neeleman, the entrepreneur behind JetBlue, in 2021. Known for its focus on providing affordable, convenient, and comfortable air travel, Breeze Airways primarily serves underserved routes, connecting smaller cities with direct flights. In this article, we delve into the airline's policies, facilities, and the benefits it offers to its passengers.</p>

        <h2 class="text-2xl font-semibold mb-4">Policies</h2>
        <p class="mb-4">Breeze Airways has established a set of customer-friendly policies designed to make air travel as smooth and hassle-free as possible:</p>
        
        <h3 class="text-xl font-semibold mb-2">1. Baggage Policy</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Carry-On:</strong> Breeze Airways allows passengers to bring one personal item, such as a purse or laptop bag, free of charge. However, carry-on bags that require overhead bin space incur an additional fee unless passengers select a fare that includes this allowance.</li>
            <li><strong>Checked Baggage:</strong> Passengers can check bags for an additional fee, with specific costs depending on the fare type and number of bags. Breeze offers different fare packages, with some including checked baggage as part of the purchase.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">2. Booking and Cancellation</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>No Change Fees:</strong> Breeze Airways stands out by not charging change fees for altering flight dates or times. Passengers can modify their bookings without incurring penalties, although fare differences may apply.</li>
            <li><strong>Cancellation Policy:</strong> Passengers can cancel their flights up to 15 minutes before departure and receive a Breeze credit for the full amount of the fare, which can be used for future travel.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">3. Special Assistance</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Accessibility:</strong> Breeze Airways is committed to providing support for passengers with disabilities or special needs. The airline offers assistance with boarding, seating, and any other required accommodations.</li>
            <li><strong>Traveling with Pets:</strong> Breeze allows small pets in the cabin for a fee, provided they fit in a carrier that can be stowed under the seat. The airline also accommodates service animals in accordance with federal guidelines.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">4. Safety and Security</h3>
        <p class="mb-4">Breeze Airways operates under strict safety regulations, ensuring that all flights meet the highest standards of safety and security. The airline’s crew is trained extensively, and its aircraft are maintained regularly to guarantee passenger safety.</p>

        <h2 class="text-2xl font-semibold mb-4">Facilities</h2>
        <p class="mb-4">Despite being a budget airline, Breeze Airways offers a range of facilities aimed at enhancing the travel experience for its passengers:</p>
        
        <h3 class="text-xl font-semibold mb-2">1. Aircraft</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Fleet:</strong> Breeze Airways operates a modern fleet of Airbus A220-300s and Embraer E190/E195 aircraft. These planes are known for their efficiency, comfort, and fuel economy, contributing to a quieter, more pleasant flight experience.</li>
            <li><strong>Comfort:</strong> The A220s, in particular, offer wider seats and more legroom compared to typical budget airlines, making them popular among passengers who value comfort on longer routes.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">2. Seating Options</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Seating Tiers:</strong> Breeze offers three seating tiers: Nice, Nicer, and Nicest. The "Nice" option is the standard economy seating, while "Nicer" includes more legroom, and "Nicest" offers a first-class experience with even more spacious seating, priority boarding, and additional amenities.</li>
            <li><strong>USB Ports:</strong> All seats are equipped with USB ports, and on select flights, in-seat power outlets are available, allowing passengers to keep their devices charged during the journey.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">3. Onboard Services</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Service:</strong> Breeze Airways provides a streamlined onboard service, with a focus on simplicity and efficiency. Passengers can purchase snacks and beverages during the flight.</li>
            <li><strong>Entertainment:</strong> The airline does not currently offer in-flight entertainment systems, but passengers are encouraged to bring their own devices and enjoy their preferred entertainment.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">4. Customer Service</h3>
        <p class="mb-4">Breeze Airways prides itself on offering friendly and responsive customer service. The airline's team is available to assist with any questions or concerns, both at the airport and during the flight, ensuring a positive experience for all passengers.</p>

        <h2 class="text-2xl font-semibold mb-4">Airline Benefits</h2>
        <p class="mb-4">Breeze Airways offers several benefits that make it an attractive choice for budget-conscious travelers and those seeking convenience:</p>
        
        <h3 class="text-xl font-semibold mb-2">1. Affordable Fares</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Pricing:</strong> Breeze Airways was designed with affordability in mind, offering competitive pricing on direct flights to underserved cities. The airline frequently offers promotional fares and discounts, making it a budget-friendly option for many travelers.</li>
            <li><strong>Customizable Travel:</strong> The airline’s fare structure is simple, with the ability to add services such as baggage, seat selection, and priority boarding as needed, allowing passengers to customize their travel experience.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">2. Non-Stop Flights to Underserved Markets</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Direct Connections:</strong> One of Breeze Airways’ key selling points is its focus on non-stop flights between smaller, underserved markets that are often overlooked by larger airlines. This eliminates the need for connecting flights, saving passengers time and making travel more convenient.</li>
            <li><strong>New Travel Opportunities:</strong> By offering direct flights to these cities, Breeze opens up new travel opportunities for both leisure and business travelers who might otherwise face long drives or multiple connections.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">3. No Change Fees</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Flexibility:</strong> Breeze’s policy of not charging change fees is a significant advantage, especially in a post-pandemic world where travel plans can be unpredictable. This flexibility gives passengers peace of mind, knowing they can adjust their travel dates without financial penalties.</li>
            <li><strong>Competitive Edge:</strong> This policy, combined with affordable fares, makes Breeze a strong competitor in the budget airline market.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">4. Sustainability and Efficiency</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Modern Fleet:</strong> Breeze Airways is committed to operating a modern, fuel-efficient fleet, which helps reduce the airline’s carbon footprint. The use of newer, more efficient aircraft not only lowers operational costs but also supports the airline’s environmental goals.</li>
            <li><strong>Environmental Goals:</strong> The A220 aircraft, in particular, are designed to be environmentally friendly, with reduced emissions and noise levels, contributing to a more sustainable airline industry.</li>
        </ul>
    </div>`,
  },
  {
    title: "Sun Country Airlines",
    image: "",
    content: `<div >
        <h1 class="text-3xl font-bold mb-6">Sun Country Airlines: Policies, Facilities, and Benefits</h1>
        <p class="mb-6">Sun Country Airlines, based in Minneapolis, Minnesota, is a low-cost carrier that has been providing air travel services since 1982. Known for its focus on leisure travel and providing affordable options to popular vacation destinations, Sun Country Airlines has evolved into a versatile airline that also offers cargo and charter services. In this article, we explore Sun Country’s policies, facilities, and the benefits it offers to its passengers.</p>

        <h2 class="text-2xl font-semibold mb-4">Policies</h2>
        <p class="mb-4">Sun Country Airlines operates with a set of policies designed to offer flexibility, affordability, and convenience to its passengers. Here are some of the key policies:</p>
        
        <h3 class="text-xl font-semibold mb-2">1. Baggage Policy</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Carry-On:</strong> Passengers are allowed one personal item, such as a purse or laptop bag, free of charge. However, carry-on bags that require overhead bin space are subject to an additional fee, depending on the fare type and whether the bag is paid for in advance or at the airport.</li>
            <li><strong>Checked Baggage:</strong> Checked baggage is also available for an additional fee, with costs varying depending on when the bag is purchased (e.g., during booking, at check-in, or at the gate). The airline has specific size and weight limits for checked baggage, and excess baggage fees apply for overweight or oversized items.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">2. Booking and Cancellation</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Flexible Fares:</strong> Sun Country offers several fare classes, each with different levels of flexibility. The airline’s most flexible fares allow changes or cancellations with a refund in the form of a travel credit, while more restrictive fares may incur fees for changes.</li>
            <li><strong>Cancellation Policy:</strong> Passengers can cancel their reservations within 24 hours of booking for a full refund, as long as the flight departs at least seven days later. For cancellations outside this window, passengers may receive a travel credit, depending on the fare class.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">3. Special Assistance</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Accessibility:</strong> Sun Country Airlines is committed to providing a comfortable travel experience for passengers with disabilities. The airline offers assistance with boarding, seating, and any special requirements during the flight.</li>
            <li><strong>Traveling with Pets:</strong> Sun Country allows small pets in the cabin for an additional fee. The airline also accommodates service animals, following federal regulations to ensure passengers receive the necessary support.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">4. Safety and Security</h3>
        <p class="mb-4">Sun Country adheres to rigorous safety standards, complying with all Federal Aviation Administration (FAA) regulations. The airline conducts regular maintenance on its aircraft and ensures that its crew is well-trained to handle all safety procedures.</p>

        <h2 class="text-2xl font-semibold mb-4">Facilities</h2>
        <p class="mb-4">As a low-cost carrier, Sun Country Airlines offers a range of facilities designed to provide value while maintaining passenger comfort:</p>
        
        <h3 class="text-xl font-semibold mb-2">1. Aircraft</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Fleet:</strong> Sun Country operates a fleet of Boeing 737-800 aircraft, which are configured for both passenger and cargo services. The 737-800 is known for its efficiency and comfort on both short and medium-haul flights.</li>
            <li><strong>Amenities:</strong> The aircraft are equipped with modern amenities, including comfortable seating, in-seat power outlets on select planes, and spacious overhead bins.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">2. Seating Options</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Seating Tiers:</strong> Sun Country offers three seating options: Standard, Better, and Best. Standard seating provides good legroom and comfort, while Better and Best seats offer extra legroom, priority boarding, and additional benefits.</li>
            <li><strong>Seating Choices:</strong> Passengers can choose their preferred seating during booking or check-in, with the option to upgrade for more space and comfort.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">3. Onboard Services</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Food and Beverage:</strong> Sun Country offers a selection of snacks and beverages for purchase on all flights. The airline also provides a variety of meal options for longer flights, including premium selections for passengers seated in the Best seats.</li>
            <li><strong>Entertainment:</strong> Sun Country provides an in-flight entertainment system accessible via personal devices. Passengers can stream movies, TV shows, and music directly to their smartphones or tablets during the flight.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">4. Customer Service</h3>
        <p class="mb-4">Sun Country is known for its friendly and helpful customer service, both at the airport and onboard. The airline’s staff is trained to assist passengers with any issues, ensuring a smooth and enjoyable travel experience.</p>

        <h2 class="text-2xl font-semibold mb-4">Airline Benefits</h2>
        <p class="mb-4">Sun Country Airlines offers several benefits that make it an appealing choice for leisure travelers and those seeking affordable air travel:</p>
        
        <h3 class="text-xl font-semibold mb-2">1. Affordable Fares</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Pricing:</strong> Sun Country’s low-cost model allows the airline to offer competitive fares to popular destinations, particularly within the United States, Mexico, Central America, and the Caribbean. The airline frequently runs promotions and offers seasonal discounts, making it an attractive option for budget-conscious travelers.</li>
            <li><strong>Customizable Travel:</strong> The airline’s fare structure is straightforward, with the ability to add services such as checked baggage, seat selection, and priority boarding as needed, giving passengers control over their travel costs.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">2. Vacation Packages</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Package Deals:</strong> Sun Country is well-known for its vacation packages, which combine flights, hotels, and car rentals at discounted rates. These packages are tailored to popular leisure destinations and are designed to provide convenience and savings for travelers planning a complete vacation experience.</li>
            <li><strong>Special Offers:</strong> The airline also offers special deals on theme park tickets, tours, and other activities, allowing passengers to bundle their travel needs into one easy-to-book package.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">3. Loyalty Program</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Sun Country Rewards:</strong> Sun Country Rewards is the airline’s frequent flyer program, allowing passengers to earn points on flights, vacation packages, and other purchases. These points can be redeemed for future flights, seat upgrades, and other rewards.</li>
            <li><strong>Additional Benefits:</strong> The loyalty program also offers benefits such as bonus points for higher-tier members, priority boarding, and discounts on in-flight purchases, making it a valuable option for frequent travelers.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">4. Non-Stop Flights to Popular Destinations</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Direct Flights:</strong> Sun Country specializes in non-stop flights to popular vacation destinations, eliminating the need for connecting flights and making travel more convenient. The airline focuses on routes to sunny locations, beach destinations, and major tourist spots, appealing to leisure travelers looking for a quick and direct flight.</li>
            <li><strong>Flexible Options:</strong> The airline’s extensive route network includes seasonal and charter flights, providing flexibility and additional options for travelers planning vacations during peak travel times.</li>
        </ul>
    </div>`,
  },
  {
    title: "SkyWest Airlines",
    image: "",
    content: ` <div >
        <h1 class="text-3xl font-bold mb-6">SkyWest Airlines: Policies, Facilities, and Benefits</h1>
        <p class="mb-6">SkyWest Airlines, a prominent regional carrier based in the U.S., provides essential air services across various regions. Operating on behalf of major airlines, SkyWest focuses on regional connectivity, offering passengers the benefits of major carriers' networks while ensuring comfortable regional flights. Below is an overview of SkyWest’s policies, facilities, and the benefits it offers to its passengers.</p>

        <h2 class="text-2xl font-semibold mb-4">Policies</h2>
        <p class="mb-4">SkyWest Airlines adheres to policies set by its partner airlines, providing flexibility and convenience for travelers. Here are the key policies:</p>
        
        <h3 class="text-xl font-semibold mb-2">1. Baggage Policy</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Carry-On:</strong> Passengers are typically allowed one carry-on bag and one personal item, such as a purse or laptop bag. The carry-on bag must adhere to the size and weight restrictions set by the partner airline.</li>
            <li><strong>Checked Baggage:</strong> Checked baggage allowances, fees, and policies vary depending on the operating airline. Generally, passengers can check one or two bags, with specific size and weight limits. Excess baggage fees apply for overweight or oversized items.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">2. Booking and Cancellation</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Flexible Booking:</strong> SkyWest adheres to the booking and cancellation policies of its partner airlines. These policies often include options for flexible booking, allowing passengers to make changes to their travel plans without hefty penalties, depending on the fare class.</li>
            <li><strong>Cancellation Policy:</strong> Cancellation policies vary by partner airline but often include options for refunds, travel credits, or rebooking with or without a fee, depending on the timing and fare type.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">3. Special Assistance</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Accessibility:</strong> SkyWest is committed to providing accessible travel for passengers with disabilities. The airline offers assistance with boarding, seating, and in-flight needs, in line with the guidelines of its partner airlines.</li>
            <li><strong>Service Animals:</strong> Passengers traveling with service animals are accommodated according to the policies of the operating airline, ensuring that all necessary support is provided.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">4. Safety and Security</h3>
        <p class="mb-4">SkyWest Airlines follows strict safety standards and complies with all Federal Aviation Administration (FAA) regulations. The airline regularly maintains its aircraft and ensures that its crew members are well-trained in all aspects of flight safety.</p>

        <h2 class="text-2xl font-semibold mb-4">Facilities</h2>
        <p class="mb-4">As a regional carrier, SkyWest Airlines ensures passengers have access to essential facilities for a comfortable travel experience:</p>
        
        <h3 class="text-xl font-semibold mb-2">1. Aircraft</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Fleet:</strong> SkyWest operates a fleet of regional jets, primarily including Bombardier CRJ-200, CRJ-700, CRJ-900, and Embraer E175 aircraft. These jets are known for their reliability and efficiency on regional routes, offering a balance of comfort and performance.</li>
            <li><strong>Amenities:</strong> The aircraft are equipped with standard amenities, including comfortable seating, overhead storage bins, and modern safety features. Some aircraft offer first-class seating and in-seat power outlets, depending on the configuration set by the operating airline.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">2. Airport Facilities</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Lounges:</strong> SkyWest does not operate its own lounges or dedicated airport facilities. However, passengers flying with SkyWest on behalf of a major airline can access that airline’s facilities, including lounges, priority boarding, and other amenities, depending on their ticket type and frequent flyer status.</li>
            <li><strong>Regional Airports:</strong> The airline serves many regional airports, where passengers can expect standard facilities such as check-in counters, security checkpoints, and baggage claim areas.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">3. Onboard Services</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Food and Beverage:</strong> Onboard services vary depending on the partner airline’s standards. Typically, passengers can expect complimentary beverages and light snacks on shorter flights, with more extensive offerings on longer routes.</li>
            <li><strong>Entertainment:</strong> In-flight entertainment options also depend on the operating airline, with some flights offering Wi-Fi, streaming services, or seatback entertainment systems.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">4. Customer Service</h3>
        <p class="mb-4">SkyWest is known for its professional and friendly customer service. The airline’s crew members are trained to assist passengers with a wide range of needs, ensuring a positive experience from check-in to landing.</p>

        <h2 class="text-2xl font-semibold mb-4">Airline Benefits</h2>
        <p class="mb-4">Flying with SkyWest Airlines offers several benefits, particularly for passengers who value regional connectivity and the advantages of flying with major carriers:</p>
        
        <h3 class="text-xl font-semibold mb-2">1. Partnerships with Major Airlines</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Major Carriers:</strong> SkyWest operates flights on behalf of Delta Air Lines, United Airlines, American Airlines, and Alaska Airlines. This partnership allows passengers to seamlessly connect to the broader networks of these major carriers, accessing a wide range of destinations across the U.S. and beyond.</li>
            <li><strong>Coordinated Scheduling:</strong> Passengers benefit from coordinated scheduling, which ensures smooth connections between regional flights operated by SkyWest and long-haul flights with partner airlines.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">2. Frequent Flyer Programs</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Miles and Rewards:</strong> Since SkyWest operates under major airline brands, passengers earn miles in the frequent flyer programs of those carriers when flying on SkyWest-operated flights. These miles can be redeemed for various rewards, including free flights, upgrades, and other perks.</li>
            <li><strong>Elite Status Benefits:</strong> Elite status members with partner airlines enjoy benefits such as priority boarding, extra baggage allowance, and access to premium lounges when traveling on SkyWest-operated flights.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">3. Regional Connectivity</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Connecting Smaller Cities:</strong> SkyWest specializes in connecting smaller cities and towns with major airport hubs, providing essential air service to communities that might otherwise be underserved. This regional connectivity is crucial for business travelers, tourists, and residents in these areas.</li>
            <li><strong>Efficient Service:</strong> The airline’s reliable service on these routes ensures that passengers can reach their destinations efficiently, without the need for long drives or multiple connecting flights.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">4. Operational Efficiency</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Short-Haul Focus:</strong> SkyWest’s fleet of regional jets is optimized for short-haul flights, offering a balance of comfort and efficiency. The airline’s operational focus on regional routes allows it to maintain punctuality and reliability, reducing the likelihood of delays or cancellations.</li>
            <li><strong>Timely Departures:</strong> Passengers can rely on SkyWest for timely departures and arrivals, making it a preferred choice for those who value time and convenience.</li>
        </ul>
    </div>`,
  },
  {
    title: "PSA Airlines",
    image: "",
    content: `<div >
        <h1 class="text-3xl font-bold mb-6">PSA Airlines: Policies, Facilities, and Benefits</h1>
        <p class="mb-6">PSA Airlines, a key regional carrier based in Dayton, Ohio, operates as a wholly-owned subsidiary of American Airlines Group. Serving as a vital part of American Airlines' regional network, PSA Airlines connects smaller cities with major hubs across the U.S. This overview provides details on PSA Airlines' policies, facilities, and benefits for passengers.</p>

        <h2 class="text-2xl font-semibold mb-4">Policies</h2>
        <p class="mb-4">PSA Airlines follows the policies established by American Airlines. Here are some of the key policies:</p>
        
        <h3 class="text-xl font-semibold mb-2">1. Baggage Policy</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Carry-On:</strong> Passengers are generally allowed to bring one carry-on bag and one personal item, such as a purse or laptop bag, onboard. The carry-on bag must meet the size and weight restrictions set by American Airlines.</li>
            <li><strong>Checked Baggage:</strong> Checked baggage policies, including fees and allowances, follow the guidelines of American Airlines. Passengers are typically allowed one or two checked bags, with specific size and weight limits. Additional fees apply for overweight or oversized baggage.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">2. Booking and Cancellation</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Flexible Booking:</strong> PSA Airlines follows the booking and cancellation policies of American Airlines, offering various fare classes that cater to different levels of flexibility. Depending on the fare type, passengers may have the option to change or cancel their bookings with minimal or no fees.</li>
            <li><strong>Cancellation Policy:</strong> Passengers can cancel their reservations within 24 hours of booking for a full refund, as long as the flight departs at least seven days later. Outside of this window, cancellations may incur fees or result in a travel credit, depending on the fare type.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">3. Special Assistance</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Accessibility:</strong> PSA Airlines is committed to ensuring accessible travel for passengers with disabilities. The airline offers assistance with boarding, seating, and in-flight needs in line with American Airlines’ guidelines.</li>
            <li><strong>Service Animals:</strong> Passengers traveling with service animals are accommodated according to American Airlines' policies, ensuring all necessary support is provided.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">4. Safety and Security</h3>
        <p class="mb-4">PSA Airlines operates under strict safety standards, adhering to all Federal Aviation Administration (FAA) regulations. The airline’s fleet is regularly maintained, and its crew members are trained to ensure the highest levels of safety and security.</p>

        <h2 class="text-2xl font-semibold mb-4">Facilities</h2>
        <p class="mb-4">Despite operating smaller aircraft, PSA Airlines ensures passengers have access to essential facilities for a comfortable journey:</p>
        
        <h3 class="text-xl font-semibold mb-2">1. Aircraft</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Fleet:</strong> PSA Airlines operates a fleet of Bombardier CRJ-700 and CRJ-900 regional jets. These aircraft are known for their reliability and efficiency on short-haul routes, offering a comfortable travel experience.</li>
            <li><strong>Seating:</strong> The CRJ-700 and CRJ-900 are configured with both economy and first-class seating, providing options for passengers seeking additional comfort.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">2. Airport Facilities</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Lounges:</strong> PSA Airlines does not operate its own lounges or dedicated airport facilities. However, passengers flying with PSA Airlines under the American Eagle brand can access American Airlines’ airport facilities, including Admirals Club lounges, priority check-in, and other amenities, depending on their ticket type and frequent flyer status.</li>
            <li><strong>Regional Airports:</strong> The airline primarily serves regional airports, which typically offer standard facilities such as check-in counters, security checkpoints, and baggage claim areas.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">3. Onboard Services</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Food and Beverage:</strong> Onboard services on PSA Airlines flights are consistent with American Airlines’ standards. Passengers can expect complimentary beverages and light snacks on shorter flights, with more extensive offerings on longer routes.</li>
            <li><strong>Entertainment:</strong> PSA Airlines does not offer seatback entertainment systems. However, passengers can access American Airlines’ in-flight entertainment via personal devices on flights that offer Wi-Fi and streaming services.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">4. Customer Service</h3>
        <p class="mb-4">PSA Airlines is known for its professional and attentive customer service. The airline’s crew members are trained to assist passengers with various needs, ensuring a pleasant travel experience from check-in to arrival.</p>

        <h2 class="text-2xl font-semibold mb-4">Airline Benefits</h2>
        <p class="mb-4">Flying with PSA Airlines offers several benefits, particularly for passengers who value regional connectivity and the advantages of flying with American Airlines:</p>
        
        <h3 class="text-xl font-semibold mb-2">1. Partnership with American Airlines</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Network Integration:</strong> As a regional carrier for American Airlines, PSA Airlines seamlessly connects passengers to American’s extensive network of destinations across the United States and around the world. This partnership allows passengers to book flights through American Airlines and enjoy coordinated schedules for smooth connections.</li>
            <li><strong>Shared Services:</strong> Passengers also benefit from the shared services and policies of American Airlines, including frequent flyer programs, baggage handling, and customer service.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">2. Frequent Flyer Program</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>AAdvantage Program:</strong> Passengers flying with PSA Airlines earn miles in the AAdvantage frequent flyer program, American Airlines’ loyalty program. These miles can be redeemed for flights, upgrades, and other rewards within the American Airlines network.</li>
            <li><strong>Elite Status Benefits:</strong> AAdvantage elite status members enjoy additional benefits such as priority boarding, extra baggage allowances, and access to premium lounges when traveling on PSA Airlines-operated flights.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">3. Regional Connectivity</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Connecting Smaller Cities:</strong> PSA Airlines specializes in connecting smaller cities and towns to major airport hubs, providing essential air service to regions that might otherwise be underserved. This regional connectivity is crucial for business travelers, tourists, and residents in these areas.</li>
            <li><strong>Efficient Service:</strong> The airline’s reliable service on these routes ensures that passengers can reach their destinations efficiently, without the need for long drives or multiple connecting flights.</li>
        </ul>

        <h3 class="text-xl font-semibold mb-2">4. Operational Efficiency</h3>
        <ul class="list-disc list-inside mb-4">
            <li><strong>Short-Haul Optimization:</strong> PSA Airlines’ fleet of regional jets is optimized for short-haul flights, offering a balance of comfort and efficiency. The airline’s focus on regional routes allows it to maintain punctuality and reliability, reducing the likelihood of delays or cancellations.</li>
            <li><strong>Timely Departures:</strong> Passengers can rely on PSA Airlines for timely departures and arrivals, making it a preferred choice for those who value time and convenience.</li>
        </ul>
    </div>`,
  },
  {
    title: "Avelo Airlines",
    image: "",
    content: `<div >
        <!-- Header -->
     
            <h1 class="text-4xl font-bold >Avelo Airlines & United Airlines</h1>
            <p class="text-center text-lg text-gray-700 mt-2">Policies, Facilities, and Airline Benefits</p>
 

        <!-- Avelo Airlines Section -->
        <section class="mb-16">
            <h2 class="text-3xl font-semibold text-blue-800">Avelo Airlines</h2>
            <p class="mt-4">Avelo Airlines, a low-cost carrier based in the United States, began operations in April 2021 with a mission to offer affordable and convenient travel options. Headquartered in Houston, Texas, and with primary bases at Hollywood Burbank Airport (BUR) in California and Tweed-New Haven Airport (HVN) in Connecticut, Avelo Airlines aims to connect underserved regions to popular destinations across the country.</p>
            
            <!-- Policies -->
            <div class="mt-8">
                <h3 class="text-2xl font-semibold text-blue-700">Policies</h3>
                <div class="mt-4 space-y-6">
                    <div>
                        <h4 class="text-xl font-medium">Baggage Policy:</h4>
                        <ul class="list-disc list-inside ml-4 mt-2 text-gray-700">
                            <li><strong>Carry-On:</strong> Passengers are allowed one personal item, such as a purse, laptop bag, or small backpack, free of charge. Standard carry-on bags are subject to an additional fee.</li>
                            <li><strong>Checked Baggage:</strong> Avelo Airlines offers checked baggage for an additional fee, with specific size and weight limits.</li>
                        </ul>
                    </div>
                    <div>
                        <h4 class="text-xl font-medium">Booking and Cancellation:</h4>
                        <ul class="list-disc list-inside ml-4 mt-2 text-gray-700">
                            <li><strong>Flexible Fares:</strong> Allows changes to reservations with ease; changes may incur a fee.</li>
                            <li><strong>Cancellation Policy:</strong> Full refund within 24 hours of booking if the flight departs at least seven days later.</li>
                        </ul>
                    </div>
                    <div>
                        <h4 class="text-xl font-medium">Special Assistance:</h4>
                        <ul class="list-disc list-inside ml-4 mt-2 text-gray-700">
                            <li><strong>Accessibility:</strong> Assistance with boarding, seating, and in-flight needs.</li>
                            <li><strong>Traveling with Pets:</strong> Small pets allowed in the cabin for a fee; service animals accommodated.</li>
                        </ul>
                    </div>
                    <div>
                        <h4 class="text-xl font-medium">Safety and Security:</h4>
                        <p class="ml-4 mt-2 text-gray-700">Adheres to rigorous safety standards, complying with all FAA regulations.</p>
                    </div>
                </div>
            </div>

            <!-- Facilities -->
            <div class="mt-8">
                <h3 class="text-2xl font-semibold text-blue-700">Facilities</h3>
                <div class="mt-4 space-y-6">
                    <div>
                        <h4 class="text-xl font-medium">Aircraft:</h4>
                        <p class="ml-4 mt-2 text-gray-700">Avelo Airlines operates a fleet of Boeing 737-700 and 737-800 aircraft, offering a comfortable travel experience.</p>
                    </div>
                    <div>
                        <h4 class="text-xl font-medium">Seating Options:</h4>
                        <p class="ml-4 mt-2 text-gray-700">Standard seating with good legroom and comfort; extra legroom seats available for purchase.</p>
                    </div>
                    <div>
                        <h4 class="text-xl font-medium">Onboard Services:</h4>
                        <ul class="list-disc list-inside ml-4 mt-2 text-gray-700">
                            <li><strong>Food and Beverage:</strong> Selection of snacks and beverages for purchase on board.</li>
                            <li><strong>In-Flight Entertainment:</strong> No seatback entertainment; passengers encouraged to bring their own devices.</li>
                        </ul>
                    </div>
                    <div>
                        <h4 class="text-xl font-medium">Customer Service:</h4>
                        <p class="ml-4 mt-2 text-gray-700">Friendly and helpful customer service, accessible through various channels.</p>
                    </div>
                </div>
            </div>

            <!-- Benefits -->
            <div class="mt-8">
                <h3 class="text-2xl font-semibold text-blue-700">Airline Benefits</h3>
                <div class="mt-4 space-y-6">
                    <div>
                        <h4 class="text-xl font-medium">Affordable Fares:</h4>
                        <p class="ml-4 mt-2 text-gray-700">Avelo Airlines provides competitive pricing with an à la carte approach to services.</p>
                    </div>
                    <div>
                        <h4 class="text-xl font-medium">Non-Stop Flights to Underserved Destinations:</h4>
                        <p class="ml-4 mt-2 text-gray-700">Offering non-stop flights to smaller airports and popular leisure destinations.</p>
                    </div>
                    <div>
                        <h4 class="text-xl font-medium">Streamlined Travel Experience:</h4>
                        <p class="ml-4 mt-2 text-gray-700">Focus on simplicity and efficiency with quick boarding, timely departures, and reliable service.</p>
                    </div>
                </div>
            </div>
        </section>

        <!-- United Airlines Section -->
        <section>
            <h2 class="text-3xl font-semibold text-blue-800">United Airlines</h2>
            <p class="mt-4">United Airlines, one of the largest airlines in the world, is a major player in the global aviation industry. Founded in 1926 and headquartered in Chicago, Illinois, United operates an extensive network of domestic and international flights, connecting passengers to over 300 destinations across five continents.</p>
            
            <!-- Policies -->
            <div class="mt-8">
                <h3 class="text-2xl font-semibold text-blue-700">Policies</h3>
                <div class="mt-4 space-y-6">
                    <div>
                        <h4 class="text-xl font-medium">Baggage Policy:</h4>
                        <ul class="list-disc list-inside ml-4 mt-2 text-gray-700">
                            <li><strong>Carry-On:</strong> One carry-on bag and one personal item free of charge.</li>
                            <li><strong>Checked Baggage:</strong> Different allowances depending on fare class and route; additional fees for overweight or extra bags.</li>
                        </ul>
                    </div>
                    <div>
                        <h4 class="text-xl font-medium">Booking and Cancellation:</h4>
                        <ul class="list-disc list-inside ml-4 mt-2 text-gray-700">
                            <li><strong>Flexible Booking:</strong> Change or cancel flights without penalty on most fares.</li>
                            <li><strong>Cancellation Policy:</strong> Full refund within 24 hours of booking if the flight departs at least seven days later.</li>
                        </ul>
                    </div>
                    <div>
                        <h4 class="text-xl font-medium">Special Assistance:</h4>
                        <ul class="list-disc list-inside ml-4 mt-2 text-gray-700">
                            <li><strong>Accessibility:</strong> Services include wheelchair assistance, pre-boarding, and special seating arrangements.</li>
                            <li><strong>Medical and Dietary Needs:</strong> Special services such as medical oxygen or special meals can be requested in advance.</li>
                        </ul>
                    </div>
                    <div>
                        <h4 class="text-xl font-medium">Safety and Security:</h4>
                        <p class="ml-4 mt-2 text-gray-700">Adheres to stringent safety standards and complies with all FAA regulations.</p>
                    </div>
                </div>
            </div>

            <!-- Facilities -->
            <div class="mt-8">
                <h3 class="text-2xl font-semibold text-blue-700">Facilities</h3>
                <div class="mt-4 space-y-6">
                    <div>
                        <h4 class="text-xl font-medium">Aircraft:</h4>
                        <p class="ml-4 mt-2 text-gray-700">United Airlines operates a diverse fleet, offering modern technology and amenities.</p>
                    </div>
                    <div>
                        <h4 class="text-xl font-medium">Seating Options:</h4>
                        <ul class="list-disc list-inside ml-4 mt-2 text-gray-700">
                            <li><strong>Economy Class:</strong> Comfortable seats with various additional options available, such as Economy Plus with extra legroom.</li>
                            <li><strong>Premium Class:</strong> More spacious seating and additional perks.</li>
                            <li><strong>Business/First Class:</strong> Luxurious seating with lie-flat beds, premium dining, and exclusive amenities.</li>
                        </ul>
                    </div>
                    <div>
                        <h4 class="text-xl font-medium">Onboard Services:</h4>
                        <ul class="list-disc list-inside ml-4 mt-2 text-gray-700">
                            <li><strong>Food and Beverage:</strong> Complimentary snacks and beverages; meals on longer flights with options for special dietary needs.</li>
                            <li><strong>In-Flight Entertainment:</strong> Seatback screens with a wide selection of movies, TV shows, music, and games.</li>
                            <li><strong>Wi-Fi:</strong> Available on most flights for an additional fee.</li>
                        </ul>
                    </div>
                    <div>
                        <h4 class="text-xl font-medium">Lounge Access:</h4>
                        <p class="ml-4 mt-2 text-gray-700">United Club and Polaris lounges offer comfort and amenities for eligible passengers.</p>
                    </div>
                </div>
            </div>

            <!-- Benefits -->
            <div class="mt-8">
                <h3 class="text-2xl font-semibold text-blue-700">Airline Benefits</h3>
                <div class="mt-4 space-y-6">
                    <div>
                        <h4 class="text-xl font-medium">Extensive Global Network:</h4>
                        <p class="ml-4 mt-2 text-gray-700">United Airlines connects passengers to over 300 destinations worldwide.</p>
                    </div>
                    <div>
                        <h4 class="text-xl font-medium">MileagePlus Program:</h4>
                        <p class="ml-4 mt-2 text-gray-700">Frequent flyer program offering miles for travel, upgrades, and other rewards.</p>
                    </div>
                    <div>
                        <h4 class="text-xl font-medium">Partnerships and Alliances:</h4>
                        <p class="ml-4 mt-2 text-gray-700">Member of the Star Alliance, offering seamless connections with partner airlines.</p>
                    </div>
                    <div>
                        <h4 class="text-xl font-medium">Focus on Sustainability:</h4>
                        <p class="ml-4 mt-2 text-gray-700">Initiatives include reducing carbon emissions and investing in sustainable aviation fuel.</p>
                    </div>
                </div>
            </div>
        </section>
    </div>`,
  },
  {
    title: "Allegiant Air",
    image: "",
    content: `<div >
        <h1 class="text-3xl font-bold text-center mb-6">Allegiant Air: Policies, Facilities, and Airline Benefits</h1>
        
        <p class="mb-4">Allegiant Air, a prominent low-cost airline in the United States, is known for offering budget-friendly travel options to a wide range of destinations across the country. Founded in 1997 and headquartered in Las Vegas, Nevada, Allegiant specializes in providing non-stop flights to smaller, regional airports, connecting underserved communities to popular vacation spots. The airline's unique business model focuses on leisure travel, catering to passengers seeking affordable, convenient, and straightforward flight options. In this article, we will explore Allegiant Air’s policies, facilities, and the benefits it offers to its passengers.</p>

        <h2 class="text-2xl font-semibold mt-6 mb-4">Policies</h2>
        <p class="mb-4">Allegiant Air operates with a clear and simple policy framework designed to keep costs low while ensuring a smooth travel experience for its passengers. Here are some of the key policies:</p>

        <h3 class="text-xl font-medium mt-4 mb-2">1. Baggage Policy:</h3>
        <ul class="list-disc list-inside mb-4">
            <li><span class="font-semibold">Carry-On:</span> Allegiant Air allows passengers to bring one personal item, such as a purse, laptop bag, or small backpack, free of charge. This item must fit under the seat in front of you. Standard carry-on bags that fit in the overhead bin are subject to additional fees. Passengers are encouraged to purchase carry-on baggage at the time of booking to secure the lowest price.</li>
            <li><span class="font-semibold">Checked Baggage:</span> Checked baggage is also subject to fees, with the cost varying depending on when the baggage is purchased (during booking, at check-in, or at the airport). Allegiant has specific size and weight restrictions for checked bags, with additional charges for oversized or overweight luggage.</li>
        </ul>

        <h3 class="text-xl font-medium mt-4 mb-2">2. Booking and Cancellation:</h3>
        <ul class="list-disc list-inside mb-4">
            <li><span class="font-semibold">Fare Options:</span> Allegiant Air offers different fare options, including a base fare with the option to add on services like seat selection, priority boarding, and baggage. This à la carte pricing structure allows passengers to pay only for the services they need, helping to keep base fares low.</li>
            <li><span class="font-semibold">Cancellation Policy:</span> Allegiant’s cancellation policy varies depending on the type of fare purchased. For standard tickets, passengers can cancel their reservations up to 24 hours after booking for a full refund, provided the flight departs at least seven days later. Beyond this window, cancellations typically result in a non-refundable credit for future travel, with change fees applicable in some cases.</li>
        </ul>

        <h3 class="text-xl font-medium mt-4 mb-2">3. Special Assistance:</h3>
        <ul class="list-disc list-inside mb-4">
            <li><span class="font-semibold">Accessibility:</span> Allegiant Air is committed to accommodating passengers with disabilities. The airline provides assistance with boarding, seating, and other in-flight needs. Passengers requiring special assistance are encouraged to notify the airline in advance to ensure that their needs are met.</li>
            <li><span class="font-semibold">Traveling with Pets:</span> Allegiant Air allows small pets to travel in the cabin for a fee. The airline also follows federal regulations concerning service animals, ensuring that all passengers can travel comfortably and safely.</li>
        </ul>

        <h3 class="text-xl font-medium mt-4 mb-2">4. Safety and Security:</h3>
        <ul class="list-disc list-inside mb-4">
            <li><span class="font-semibold">Safety Standards:</span> Allegiant Air adheres to all Federal Aviation Administration (FAA) safety regulations. The airline’s fleet is regularly maintained, and its crew members are thoroughly trained to handle safety procedures. Allegiant’s focus on safety is a top priority, ensuring that passengers have a secure travel experience.</li>
        </ul>

        <h2 class="text-2xl font-semibold mt-6 mb-4">Facilities</h2>
        <p class="mb-4">As a low-cost carrier, Allegiant Air offers a range of facilities aimed at providing a comfortable and efficient travel experience, while maintaining affordability:</p>

        <h3 class="text-xl font-medium mt-4 mb-2">1. Aircraft:</h3>
        <ul class="list-disc list-inside mb-4">
            <li><span class="font-semibold">Fleet:</span> Allegiant Air operates a fleet primarily composed of Airbus A319 and A320 aircraft. These aircraft are well-suited for short to medium-haul flights, offering reliable and efficient service.</li>
            <li><span class="font-semibold">Seating Configuration:</span> Allegiant’s aircraft feature an all-Economy Class configuration. Seats are designed to be comfortable yet basic, reflecting the airline’s no-frills approach to air travel. Passengers have the option to purchase specific seats, including those with extra legroom, during the booking process.</li>
        </ul>

        <h3 class="text-xl font-medium mt-4 mb-2">2. Onboard Services:</h3>
        <ul class="list-disc list-inside mb-4">
            <li><span class="font-semibold">Food and Beverage:</span> Allegiant Air offers a variety of snacks and beverages for purchase on board. The airline does not provide complimentary meals, but passengers can choose from a selection of light snacks, soft drinks, and alcoholic beverages, all available for purchase during the flight.</li>
            <li><span class="font-semibold">In-Flight Entertainment:</span> Allegiant does not offer in-flight entertainment systems such as seatback screens or Wi-Fi. Passengers are encouraged to bring their own devices and entertainment options to enjoy during the flight.</li>
        </ul>

        <h3 class="text-xl font-medium mt-4 mb-2">3. Airport Services:</h3>
        <ul class="list-disc list-inside mb-4">
            <li><span class="font-semibold">Check-In Options:</span> Passengers can check in online through Allegiant’s website or mobile app, which is highly recommended to avoid long lines at the airport. For those who prefer in-person service, check-in counters are available at the airport, but additional fees may apply for airport check-in services.</li>
            <li><span class="font-semibold">Boarding Process:</span> Allegiant Air follows a boarding process based on seat assignment and fare class. Passengers who purchase priority boarding or those seated in the front rows typically board first, followed by the rest of the passengers.</li>
        </ul>

        <h2 class="text-2xl font-semibold mt-6 mb-4">Airline Benefits</h2>
        <p class="mb-4">Allegiant Air offers several benefits that cater to budget-conscious travelers looking for convenient and affordable travel options:</p>

        <h3 class="text-xl font-medium mt-4 mb-2">1. Low Fares:</h3>
        <ul class="list-disc list-inside mb-4">
            <li><span class="font-semibold">Budget-Friendly:</span> Allegiant Air is renowned for its low fares, often offering some of the cheapest flights available on its routes. By focusing on point-to-point travel to smaller, regional airports, Allegiant can keep its operating costs low, passing the savings on to passengers.</li>
            <li><span class="font-semibold">À La Carte Pricing:</span> The airline’s à la carte pricing model allows passengers to customize their travel experience by adding only the services they need, helping them save money on unnecessary extras.</li>
        </ul>

        <h3 class="text-xl font-medium mt-4 mb-2">2. Non-Stop Flights to Vacation Destinations:</h3>
        <ul class="list-disc list-inside mb-4">
            <li><span class="font-semibold">Direct Flights:</span> Allegiant Air specializes in offering non-stop flights to popular vacation destinations, including cities in Florida, Nevada, Arizona, and California. This focus on leisure travel makes Allegiant a popular choice for vacationers looking for direct and hassle-free flights to their favorite holiday spots.</li>
            <li><span class="font-semibold">Regional Connectivity:</span> The airline connects many smaller, regional airports to major tourist destinations, making it easier for passengers to reach their vacation spots without the need for connecting flights.</li>
        </ul>

        <h3 class="text-xl font-medium mt-4 mb-2">3. Convenient Flight Schedules:</h3>
        <ul class="list-disc list-inside mb-4">
            <li><span class="font-semibold">Optimized for Leisure Travel:</span> Allegiant Air operates on a less frequent flight schedule compared to major airlines, but it often offers flights during peak travel times, such as weekends and holidays. This approach is particularly beneficial for leisure travelers who plan their trips around specific dates and events.</li>
            <li><span class="font-semibold">Daytime Arrivals:</span> The airline’s flight schedules are designed to maximize convenience for vacationers, often offering flights that arrive at destinations during the day, allowing passengers to start their vacations without delay.</li>
        </ul>

        <h3 class="text-xl font-medium mt-4 mb-2">4. Allegiant World Mastercard®:</h3>
        <ul class="list-disc list-inside mb-4">
            <li><span class="font-semibold">Earn Points:</span> Allegiant Air offers the Allegiant World Mastercard®, a credit card that allows passengers to earn points on Allegiant purchases and everyday spending. Cardholders can redeem points for flights, hotel stays, car rentals, and more. The card also offers additional benefits, such as priority boarding and a free companion fare, making it a valuable tool for frequent Allegiant travelers.</li>
        </ul>
    </div>`,
  },
  {
    title: "Hawaiian Airlines",
    image: "",
    content: `<div >
        <h1 class="text-3xl font-bold text-center mb-8">Hawaiian Airlines: Policies, Facilities, and Airline Benefits</h1>
        
        <section class="mb-10">
            <p class="mb-4">
                Hawaiian Airlines, the flag carrier of the Aloha State, is renowned for offering a unique blend of comfort, service, and genuine Hawaiian hospitality. Founded in 1929 and headquartered in Honolulu, Hawaii, the airline is the largest and oldest carrier in Hawaii, providing passengers with an array of travel options to and from the islands. Hawaiian Airlines is celebrated for its dedication to delivering a distinctly Hawaiian experience from check-in to arrival. This article delves into Hawaiian Airlines' policies, facilities, and the benefits it offers to travelers.
            </p>
        </section>

        <section class="mb-10">
            <h2 class="text-2xl font-semibold mb-4">Policies</h2>
            <div class="space-y-6">
                <div>
                    <h3 class="text-xl font-semibold">Baggage Policy:</h3>
                    <ul class="list-disc list-inside ml-6 mt-2">
                        <li class="mb-2">
                            <strong>Carry-On:</strong> Passengers are allowed one carry-on bag and one personal item, such as a purse or laptop bag, free of charge. The maximum dimensions for the carry-on bag are 22 x 14 x 9 inches (56 x 36 x 23 cm) including handles and wheels. The airline also enforces a weight limit for carry-on baggage to ensure safety and compliance.
                        </li>
                        <li>
                            <strong>Checked Baggage:</strong> Hawaiian Airlines permits one free checked bag for passengers traveling in Economy Class on inter-island flights, and two free checked bags for transpacific flights. Additional baggage fees apply for extra or oversized bags. Business Class and First Class passengers enjoy additional baggage allowances and benefits.
                        </li>
                    </ul>
                </div>
                <div>
                    <h3 class="text-xl font-semibold">Booking and Cancellation:</h3>
                    <ul class="list-disc list-inside ml-6 mt-2">
                        <li class="mb-2">
                            <strong>Flexible Booking:</strong> Hawaiian Airlines offers flexible booking options for most fare types. Passengers can change their flight dates or destination with a fee, depending on the fare rules. Some fare classes offer more flexibility with fewer restrictions.
                        </li>
                        <li>
                            <strong>Cancellation Policy:</strong> Passengers can cancel their bookings within 24 hours of purchase for a full refund, provided the flight is at least seven days away. For cancellations made outside this window, Hawaiian Airlines typically provides a travel credit rather than a cash refund, with fees applicable based on the fare type and timing.
                        </li>
                    </ul>
                </div>
                <div>
                    <h3 class="text-xl font-semibold">Special Assistance:</h3>
                    <ul class="list-disc list-inside ml-6 mt-2">
                        <li class="mb-2">
                            <strong>Accessibility:</strong> Hawaiian Airlines is dedicated to providing accessible travel for passengers with disabilities. The airline offers wheelchair assistance, priority boarding, and other necessary support services. Passengers requiring special assistance are encouraged to notify the airline in advance to ensure appropriate accommodations.
                        </li>
                        <li>
                            <strong>Traveling with Pets:</strong> Hawaiian Airlines allows small pets to travel in the cabin for a fee. The airline follows federal regulations regarding service animals and provides accommodations for passengers traveling with trained service animals.
                        </li>
                    </ul>
                </div>
                <div>
                    <h3 class="text-xl font-semibold">Safety and Security:</h3>
                    <p class="mt-2">Hawaiian Airlines adheres to all Federal Aviation Administration (FAA) regulations and follows stringent safety protocols. The airline's fleet undergoes regular maintenance checks, and its crew members are extensively trained in safety procedures to ensure a secure travel experience.</p>
                </div>
            </div>
        </section>

        <section class="mb-10">
            <h2 class="text-2xl font-semibold mb-4">Facilities</h2>
            <div class="space-y-6">
                <div>
                    <h3 class="text-xl font-semibold">Aircraft:</h3>
                    <ul class="list-disc list-inside ml-6 mt-2">
                        <li class="mb-2">
                            <strong>Modern Fleet:</strong> Hawaiian Airlines operates a modern fleet of aircraft, including Airbus A321neo, A330, and Boeing 717 models. These aircraft are equipped with amenities designed to provide a comfortable flying experience for passengers.
                        </li>
                        <li>
                            <strong>Cabin Classes:</strong> The airline offers several cabin classes, including Economy, Extra Comfort, and Business Class. Each class provides varying levels of comfort and service, from standard seating in Economy to enhanced amenities and services in Business Class.
                        </li>
                    </ul>
                </div>
                <div>
                    <h3 class="text-xl font-semibold">Airport Lounges:</h3>
                    <ul class="list-disc list-inside ml-6 mt-2">
                        <li class="mb-2">
                            <strong>Hawaiian Airlines Premier Club:</strong> The airline operates Premier Club lounges at key airports, such as Honolulu International Airport. These lounges offer a relaxing environment with amenities including complimentary snacks and beverages, Wi-Fi, and comfortable seating. The Premier Club is available to Business Class passengers and HawaiianMiles members with certain status levels.
                        </li>
                        <li>
                            <strong>Partner Lounge Access:</strong> Hawaiian Airlines’ Business Class passengers also have access to select partner lounges in international airports, enhancing their travel experience with additional amenities and services.
                        </li>
                    </ul>
                </div>
                <div>
                    <h3 class="text-xl font-semibold">Onboard Services:</h3>
                    <ul class="list-disc list-inside ml-6 mt-2">
                        <li class="mb-2">
                            <strong>In-Flight Entertainment:</strong> Hawaiian Airlines provides a range of in-flight entertainment options, including a selection of movies, TV shows, and music. The airline’s entertainment system is available on most flights, and passengers can enjoy content on seatback screens or through their personal devices.
                        </li>
                        <li class="mb-2">
                            <strong>Wi-Fi:</strong> Wi-Fi is available on select Hawaiian Airlines flights, allowing passengers to stay connected during their journey. The service is available for a fee, with various pricing options depending on the duration of the flight.
                        </li>
                        <li>
                            <strong>Dining:</strong> Hawaiian Airlines offers a selection of complimentary meals and snacks on long-haul flights, reflecting the island’s culinary influences. In Economy Class, passengers can enjoy traditional Hawaiian fare and regional specialties, while Business Class passengers receive gourmet meal service and premium beverages.
                        </li>
                    </ul>
                </div>
                <div>
                    <h3 class="text-xl font-semibold">Customer Service:</h3>
                    <p class="mt-2">Hawaiian Airlines is known for its excellent customer service and the warm hospitality that reflects the spirit of the islands. The airline’s customer service team is available through various channels, including phone, email, and social media, to assist with reservations, flight changes, baggage issues, and other inquiries.</p>
                </div>
            </div>
        </section>

        <section class="mb-10">
            <h2 class="text-2xl font-semibold mb-4">Airline Benefits</h2>
            <div class="space-y-6">
                <div>
                    <h3 class="text-xl font-semibold">Aloha Spirit:</h3>
                    <p class="mt-2">Hawaiian Airlines is renowned for its Aloha Spirit, which embodies the warmth, friendliness, and hospitality of the Hawaiian culture. This spirit is evident in the airline’s service, from check-in to in-flight interactions, providing passengers with a memorable and enjoyable travel experience.</p>
                </div>
                <div>
                    <h3 class="text-xl font-semibold">HawaiianMiles Loyalty Program:</h3>
                    <ul class="list-disc list-inside ml-6 mt-2">
                        <li class="mb-2">
                            <strong>Earn Miles:</strong> The HawaiianMiles program allows passengers to earn miles for flights, hotel stays, car rentals, and other travel-related purchases. Miles can be redeemed for free flights, upgrades, and various travel rewards. The program offers several elite status levels, each providing additional benefits such as priority boarding, extra baggage allowance, and exclusive offers.
                        </li>
                        <li>
                            <strong>Partner Benefits:</strong> HawaiianMiles members also enjoy the benefits of earning and redeeming miles with Hawaiian Airlines’ airline partners and through its partnership with the Star Alliance.
                        </li>
                    </ul>
                </div>
                <div>
                    <h3 class="text-xl font-semibold">Inter-Island and International Connectivity:</h3>
                    <p class="mt-2">Hawaiian Airlines provides extensive inter-island connectivity, making it easy for travelers to explore the different Hawaiian Islands. The airline also offers international flights to destinations in Asia, Australia, and the South Pacific, providing a seamless travel experience for those venturing beyond Hawaii.</p>
                </div>
                <div>
                    <h3 class="text-xl font-semibold">Comfort and Service:</h3>
                    <ul class="list-disc list-inside ml-6 mt-2">
                        <li class="mb-2">
                            <strong>Enhanced Comfort:</strong> Passengers in Business Class and Extra Comfort enjoy enhanced comfort with additional legroom, priority boarding, and upgraded amenities. Business Class passengers receive premium meal service, access to lounges, and other exclusive perks that elevate their travel experience.
                        </li>
                        <li>
                            <strong>Economy Class Comfort:</strong> The airline’s focus on providing a comfortable and pleasant journey extends to Economy Class as well, with complimentary beverages, in-flight entertainment, and friendly service ensuring a positive travel experience for all passengers.
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    </div>`,
  },
  {
    title: "American Airlines",
    image: "",
    content: `
            <h1 class="text-4xl font-bold">American Airlines: Policies, Facilities, and Airline Benefits</h1>


    <main class="container mx-auto p-6 bg-white shadow-md rounded-lg mt-6">
        <section>
            <h2 class="text-2xl font-semibold mb-4">Policies</h2>
            <ul class="space-y-4">
                <li>
                    <h3 class="text-xl font-semibold">1. Baggage Policy</h3>
                    <ul class="list-disc ml-5 space-y-2">
                        <li><strong>Carry-On:</strong> Passengers are allowed to bring one carry-on bag and one personal item, such as a purse or laptop bag, free of charge. The carry-on bag must fit within the size restrictions of 22 x 14 x 9 inches (56 x 36 x 23 cm) and must be stowed in the overhead bin.</li>
                        <li><strong>Checked Baggage:</strong> American Airlines allows one free checked bag for passengers traveling in Economy Class on domestic and short-haul international flights. For international long-haul flights, passengers may check up to two bags free of charge. Additional fees apply for extra, oversized, or overweight bags, with fees varying based on the route and fare class.</li>
                    </ul>
                </li>
                <li>
                    <h3 class="text-xl font-semibold">2. Booking and Cancellation</h3>
                    <ul class="list-disc ml-5 space-y-2">
                        <li><strong>Flexible Booking:</strong> American Airlines offers various fare classes with different levels of flexibility. Passengers can change or cancel their bookings, but the specific rules and fees depend on the fare type. Main Cabin and higher fare classes typically offer greater flexibility, including the option to change flights for a fee or receive a credit for future travel.</li>
                        <li><strong>Cancellation Policy:</strong> Passengers can cancel their reservations within 24 hours of booking for a full refund, provided the flight is at least two days away. After this period, cancellations may result in a travel credit or partial refund, with fees applicable depending on the fare rules and timing.</li>
                    </ul>
                </li>
                <li>
                    <h3 class="text-xl font-semibold">3. Special Assistance</h3>
                    <ul class="list-disc ml-5 space-y-2">
                        <li><strong>Accessibility:</strong> American Airlines is dedicated to providing accessible travel for passengers with disabilities. The airline offers a range of services, including wheelchair assistance, priority boarding, and support for service animals. Passengers requiring special assistance are encouraged to notify the airline in advance to ensure all needs are met.</li>
                        <li><strong>Traveling with Pets:</strong> Small pets can travel in the cabin for a fee, with specific requirements for carriers and pet dimensions. American Airlines also accommodates service animals and provides support for passengers traveling with trained service animals.</li>
                    </ul>
                </li>
                <li>
                    <h3 class="text-xl font-semibold">4. Safety and Security</h3>
                    <ul class="list-disc ml-5 space-y-2">
                        <li><strong>Safety Standards:</strong> American Airlines adheres to all Federal Aviation Administration (FAA) regulations and follows rigorous safety protocols. The airline’s fleet undergoes regular maintenance and inspections, and its crew members are extensively trained in safety procedures to ensure a secure travel experience.</li>
                    </ul>
                </li>
            </ul>
        </section>

        <section class="mt-6">
            <h2 class="text-2xl font-semibold mb-4">Facilities</h2>
            <ul class="space-y-4">
                <li>
                    <h3 class="text-xl font-semibold">1. Aircraft</h3>
                    <ul class="list-disc ml-5 space-y-2">
                        <li><strong>Fleet:</strong> American Airlines operates a diverse fleet of aircraft, including Boeing 737, 777, and 787 models, as well as Airbus A319, A320, and A321 aircraft. The fleet is equipped with modern amenities to ensure a comfortable flight experience.</li>
                        <li><strong>Cabin Classes:</strong> The airline offers multiple cabin classes, including Basic Economy, Main Cabin, Premium Economy (Main Cabin Extra), Business Class (Flagship Business), and First Class. Each class provides different levels of comfort and service, catering to various passenger preferences and budgets.</li>
                    </ul>
                </li>
                <li>
                    <h3 class="text-xl font-semibold">2. Airport Lounges</h3>
                    <ul class="list-disc ml-5 space-y-2">
                        <li><strong>Admirals Club:</strong> American Airlines operates Admirals Club lounges at major airports around the world. These lounges offer a comfortable environment with amenities such as complimentary snacks and beverages, Wi-Fi, and business services. Admirals Club membership is available for purchase or through specific fare classes and elite status levels.</li>
                        <li><strong>Flagship Lounge:</strong> The Flagship Lounge is available at select international airports for passengers traveling in First Class or Flagship Business. These lounges offer premium amenities, including fine dining, private workspaces, and luxurious shower facilities.</li>
                    </ul>
                </li>
                <li>
                    <h3 class="text-xl font-semibold">3. Onboard Services</h3>
                    <ul class="list-disc ml-5 space-y-2">
                        <li><strong>In-Flight Entertainment:</strong> American Airlines provides a robust in-flight entertainment system, including seatback screens with a wide selection of movies, TV shows, and music. Passengers can also stream content to their personal devices using the American Airlines app on most flights.</li>
                        <li><strong>Wi-Fi:</strong> Wi-Fi is available on most American Airlines flights, allowing passengers to stay connected during their journey. Various pricing options are available, including hourly and full-flight passes.</li>
                        <li><strong>Dining:</strong> The airline offers a range of dining options depending on the cabin class and flight duration. Passengers in Economy Class receive complimentary snacks and beverages on most flights, while those in Premium Economy, Business Class, and First Class enjoy gourmet meals and premium beverages.</li>
                    </ul>
                </li>
                <li>
                    <h3 class="text-xl font-semibold">4. Customer Service</h3>
                    <ul class="list-disc ml-5 space-y-2">
                        <li><strong>Support:</strong> American Airlines is known for its commitment to customer service, providing support through various channels, including phone, email, social media, and the American Airlines app. The airline’s customer service team is available 24/7 to assist with booking, flight changes, baggage issues, and other travel-related inquiries.</li>
                    </ul>
                </li>
            </ul>
        </section>

        <section class="mt-6">
            <h2 class="text-2xl font-semibold mb-4">Airline Benefits</h2>
            <ul class="space-y-4">
                <li>
                    <h3 class="text-xl font-semibold">1. AAdvantage Loyalty Program</h3>
                    <ul class="list-disc ml-5 space-y-2">
                        <li><strong>Overview:</strong> American Airlines’ AAdvantage program is one of the largest frequent flyer programs in the world. Members earn miles for flights, hotel stays, car rentals, and other purchases, which can be redeemed for free flights, upgrades, and various travel rewards. The program offers several elite status levels, including AAdvantage Gold, Platinum, Platinum Pro, and Executive Platinum, each providing additional benefits such as priority boarding, complimentary upgrades, and extra baggage allowance.</li>
                        <li><strong>Partnerships:</strong> AAdvantage members also enjoy the benefits of earning and redeeming miles with American Airlines’ airline partners and through its membership in the oneworld alliance.</li>
                    </ul>
                </li>
                <li>
                    <h3 class="text-xl font-semibold">2. oneworld Alliance Membership</h3>
                    <ul class="list-disc ml-5 space-y-2">
                        <li><strong>Global Network:</strong> As a member of the oneworld alliance, American Airlines offers passengers access to a global network of over 1,000 destinations across more than 150 countries. AAdvantage members can earn and redeem miles with oneworld partners, and elite status members enjoy additional perks such as priority check-in, extra baggage allowance, and access to over 650 airport lounges worldwide.</li>
                    </ul>
                </li>
                <li>
                    <h3 class="text-xl font-semibold">3. Extensive Route Network</h3>
                    <ul class="list-disc ml-5 space-y-2">
                        <li><strong>Destinations:</strong> American Airlines boasts an extensive route network, connecting passengers to over 350 destinations across the globe. With major hubs in Dallas/Fort Worth, Charlotte, Miami, and Philadelphia, American provides seamless connectivity for both domestic and international travel.</li>
                        <li><strong>Flexibility:</strong> The airline’s extensive network and frequent flight schedules make it a convenient choice for travelers looking for flexibility and convenience in their travel plans.</li>
                    </ul>
                </li>
                <li>
                    <h3 class="text-xl font-semibold">4. Operational Efficiency and Reliability</h3>
                    <ul class="list-disc ml-5 space-y-2">
                        <li><strong>Technology:</strong> American Airlines invests in technology and infrastructure to improve operational efficiency and reliability. The airline’s modern fleet and focus on punctuality ensure that passengers can rely on American for timely departures and arrivals.</li>
                        <li><strong>App and Website:</strong> The airline’s mobile app and website offer a user-friendly experience, allowing passengers to book flights, manage reservations, check in, and track their flights with ease.</li>
                    </ul>
                </li>
            </ul>
        </section>
    </main>
`,
  },
];
