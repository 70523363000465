import React, { useEffect, useState } from "react";
import Header from "../../Component/common/Header";
import { useParams } from "react-router";
import { destinations } from "../../Container/Data/destination";
import Footer from "../../Component/common/Footer";

function Destination() {
  const { destiny } = useParams();
  const [curretDestiny, setCurrentDestiny] = useState({});

  useEffect(() => {
    window.scroll({
      top: 0,
    });
  }, [destiny]);
  useEffect(() => {
    let getCurrentDestiny = destinations.find(
      (state) => state.title.split(" ").join("").toLowerCase() == destiny
    );
    if (getCurrentDestiny) {
      console.log("here");
      setCurrentDestiny(getCurrentDestiny);
      let contentDiv = document.getElementById("content");
      contentDiv.innerHTML = getCurrentDestiny.content;
    }
  }, [destiny]);
  return (
    <>
      <div className="bg-[#f3f3f3] px-24 py-4 relative  max-md:px-10 h-auto w-full max-md:h-auto  max-md:pb-0 max-md:-mb-10 max-sm:px-4">
        <Header color={"white"} />
        <div className="py-12">
          <div className="font-bold text-5xl">{curretDestiny?.title}</div>
          <div className="w-full h-[350px] mt-3 mb-6">
            <img
              src={curretDestiny.image}
              className="w-full h-full object-cover object-center"
              alt=""
            />
          </div>
          <div className="" id="content"></div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Destination;
