import React from "react";
import Header from "../../Component/common/Header";
import Footer from "../../Component/common/Footer";
import { IoMdCall } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";

function Contact() {
  return (
    <div className="min-h-screen w-full">
      <div
        style={{
          minHeight: "600px",
          width: "100%",
          backgroundImage: `
        linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 0% ,rgba(0, 0, 0, 0.7)),
        url('https://images.pexels.com/photos/3408354/pexels-photo-3408354.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2')
      `,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="px-24 py-4 pb-10 max-md:px-10 max-sm:px-4"
      >
        <Header color={"black"} />
        <div>
          <div className="flex-col flex items-center justify-start py-28 h-full relative">
            <div className="text-7xl font-bold text-white  mt-3">
              Contact <span className="text-[rgb(252,108,58)]">us</span>
            </div>
            <div className="text-lg  text-white w-3/5 mt-5 max-md:w-full  max-md:text-center">
              Need help with your booking? Have questions about your trip? Our
              dedicated customer support team is here to assist you.
            </div>
          </div>
        </div>
        <div className=" bg-white w-full shadow-2xl flex items-start h-[450px] max-md:flex-wrap max-md:h-auto">
          <div className="w-3/5 px-8 h-[450px] py-12 max-md:h-auto max-md:w-full ">
            <div className="flex items-start justify-between mb-5 max-md:flex-wrap ">
              <div className="w-1/2 mr-7 max-sm:w-full max-sm:mr-0">
                <div className="font-medium">First name</div>
                <input
                  type="text"
                  placeholder="Enter your first name"
                  className="h-12 border border-black/20 w-full px-4 mt-1.5 rounded-md"
                />
              </div>
              <div className="w-1/2 mr-7 max-sm:w-full max-sm:mr-0">
                <div className="font-medium">Last name</div>
                <input
                  type="text"
                  placeholder="Enter your last name"
                  className="h-12 border border-black/20 w-full px-4 mt-1.5 rounded-md"
                />
              </div>
            </div>
            <div className="mb-5">
              <div className="font-medium">Email Address</div>
              <input
                type="text"
                placeholder="Enter your email address"
                className="h-12 border border-black/20 w-full px-4 mt-1.5 rounded-md"
              />
            </div>
            <div className="mb-5">
              <div className="font-medium">Phone number</div>
              <input
                type="text"
                placeholder="Enter valid phone number"
                className="h-12 border border-black/20 w-full px-4 mt-1.5 rounded-md"
              />
            </div>
            <div className="mt-5 flex items-center justify-end">
              {" "}
              <div className=" w-44 bg-[#fc6c3a] text-center py-3 rounded-lg">
                Send message
              </div>
            </div>
          </div>
          <div className="w-2/5 max-md:w-full bg-[#fc6c3a] h-[450px] py-12 px-12">
            <div className="text-2xl font-bold">Contact Infromation</div>
            <a href="tel:+18338352670" className="flex items-center mt-6 mb-3">
              <div className="mr-3 p-4 rounded-full bg-[#ff8a63]">
                <IoMdCall size={23} />
              </div>

              <span>+1 833-835-2670 </span>
            </a>
            <div className="flex items-center mt-6 mb-3">
              <div className="mr-3 p-4 rounded-full bg-[#ff8a63]">
                <FaLocationDot size={23} />
              </div>

              <span>
                9407 Ne Vancouver Mall Dr, Ste 104 #1647 Vancouver, Washington
                98662 Us
              </span>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
